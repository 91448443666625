import { render, staticRenderFns } from "./Archived.vue?vue&type=template&id=509a91a6&scoped=true"
import script from "./Archived.vue?vue&type=script&lang=js"
export * from "./Archived.vue?vue&type=script&lang=js"
import style0 from "./Archived.vue?vue&type=style&index=0&id=509a91a6&prod&lang=scss&scoped=true"
import style1 from "./Archived.vue?vue&type=style&index=1&id=509a91a6&prod&lang=scss"
import style2 from "./Archived.vue?vue&type=style&index=2&id=509a91a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "509a91a6",
  null
  
)

export default component.exports